// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.invoice-igst {
    text-align: center !important;
}
.invoice-totalvalue {
    text-align: right !important;
}`, "",{"version":3,"sources":["webpack://./src/views/SellerInvoice/invoicemodule.css"],"names":[],"mappings":";;AAEA;IACI,6BAA6B;AACjC;AACA;IACI,4BAA4B;AAChC","sourcesContent":["\r\n\r\n.invoice-igst {\r\n    text-align: center !important;\r\n}\r\n.invoice-totalvalue {\r\n    text-align: right !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
