import React from "react";

const RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.692 4.432l5.625 5.625a.625.625 0 010 .885l-5.625 5.625a.625.625 0 11-.884-.885l4.558-4.557H3.125a.625.625 0 010-1.25h12.241l-4.558-4.558a.625.625 0 01.884-.885z"
    ></path>
  </svg>
);

export default RightArrow;
