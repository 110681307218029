// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-acces-block .Admin-acces-filter {
  padding: 15px 30px;
}
.admin-acces-block .Admin-acces-filter .filter span {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 8px;
}
@media screen and (max-width: 767px) {
  .admin-acces-block .Admin-acces-filter .filter span {
    width: 100%;
  }
}
.admin-acces-block .Admin-acces-filter .filter label {
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .admin-acces-block .Admin-acces-filter .filter .react-datepicker-wrapper,
  .admin-acces-block .Admin-acces-filter .filter input {
    width: 100%;
  }
}
.admin-acces-block .seller-list-heading {
  padding: 8px 30px;
  background: #c4b7dd;
}
.admin-acces-block .seller-list-heading p {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #000;
}
.admin-acces-block .Admin-acces-table {
  padding: 20px 30px;
}
.admin-acces-block .Admin-acces-table thead td {
  background: #f3f3f3;
}`, "",{"version":3,"sources":["webpack://./src/views/Admin-Access/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAGM;EACE,qBAAA;EACA,kBAAA;EACA,kBAAA;AADR;AAGQ;EALF;IAMI,WAAA;EAAR;AACF;AAGM;EACE,kBAAA;AADR;AAMQ;EAFF;;IAGI,WAAA;EAFR;AACF;AAOE;EACE,iBAAA;EACA,mBAAA;AALJ;AAOI;EACE,SAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AALN;AASE;EACE,kBAAA;AAPJ;AAUM;EACE,mBAAA;AARR","sourcesContent":[".admin-acces-block {\n  .Admin-acces-filter {\n    padding: 15px 30px;\n\n    .filter {\n      span {\n        display: inline-block;\n        margin-right: 10px;\n        margin-bottom: 8px;\n\n        @media screen and (max-width: 767px) {\n          width: 100%;\n        }\n      }\n\n      label {\n        margin-right: 10px;\n      }\n\n      .react-datepicker-wrapper,\n      input {\n        @media screen and (max-width: 767px) {\n          width: 100%;\n        }\n      }\n    }\n  }\n\n  .seller-list-heading {\n    padding: 8px 30px;\n    background: #c4b7dd;\n\n    p {\n      margin: 0;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n      color: #000;\n    }\n  }\n\n  .Admin-acces-table {\n    padding: 20px 30px;\n\n    thead {\n      td {\n        background: #f3f3f3;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
