import React from "react";

const StoreServiceable = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.384 5.732l-4.116-4.116a1.25 1.25 0 00-1.768 0L1.616 12.5a1.25 1.25 0 000 1.768l4.116 4.115a1.25 1.25 0 001.768 0L18.384 7.5a1.25 1.25 0 000-1.768zM6.616 17.5L2.5 13.383l2.5-2.5 2.058 2.059a.626.626 0 00.884-.884L5.884 10 7.5 8.383l2.058 2.059a.625.625 0 10.884-.884L8.384 7.5 10 5.883l2.058 2.059a.626.626 0 00.884-.884L10.884 5l2.5-2.5L17.5 6.616 6.616 17.5z"
    ></path>
  </svg>
);

export default StoreServiceable;
