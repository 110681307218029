// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markerbg {
  position: fixed;
  left: 50%;
  top: 43.5%;
  transform: translate(-50%, -250%);
  background: #000;
  border-radius: 4px;
  z-index: 99;
  padding: 7px 10px;
  text-align: center;
  width: 230px;
}

.markerbg::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #000;
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translateX(-50%);
}

.markerbg h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0 0 2px 0;
  color: #fff;
}

.markerbg p {
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  margin: 0;
  padding: 0;
  color: #ccc;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EACA,UAAA;EACA,iCAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,2BAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,2BAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;AACF","sourcesContent":[".markerbg {\n  position: fixed;\n  left: 50%;\n  top: 43.5%;\n  transform: translate(-50%, -250%);\n  background: #000;\n  border-radius: 4px;\n  z-index: 99;\n  padding: 7px 10px;\n  text-align: center;\n  width: 230px;\n}\n\n.markerbg::after {\n  content: \"\";\n  width: 0;\n  height: 0;\n  border-left: 8px solid transparent;\n  border-right: 8px solid transparent;\n  border-top: 16px solid #000;\n  position: absolute;\n  top: 98%;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.markerbg h4 {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 16px;\n  margin: 0 0 2px 0;\n  color: #fff;\n}\n\n.markerbg p {\n  font-size: 12px;\n  font-weight: normal;\n  line-height: 16px;\n  margin: 0;\n  padding: 0;\n  color: #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
