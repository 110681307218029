import React from "react";

const Download = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff">
    <g data-name="Layer 2">
      <g data-name="download">
        <rect width="16" height="2" x="4" y="18" rx="1" ry="1"></rect>
        <rect
          width="4"
          height="2"
          x="3"
          y="17"
          rx="1"
          ry="1"
          transform="rotate(-90 5 18)"
        ></rect>
        <rect
          width="4"
          height="2"
          x="17"
          y="17"
          rx="1"
          ry="1"
          transform="rotate(-90 19 18)"
        ></rect>
        <path d="M12 15a1 1 0 01-.58-.18l-4-2.82a1 1 0 01-.24-1.39 1 1 0 011.4-.24L12 12.76l3.4-2.56a1 1 0 011.2 1.6l-4 3a1 1 0 01-.6.2z"></path>
        <path d="M12 13a1 1 0 01-1-1V4a1 1 0 012 0v8a1 1 0 01-1 1z"></path>
      </g>
    </g>
  </svg>
);

export default Download;
