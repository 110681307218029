import React from "react";

const ShopDetails = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.75 9c0-.07-.01-.14-.028-.206l-1.345-4.707A1.507 1.507 0 0018.937 3H5.063a1.507 1.507 0 00-1.439 1.088L2.28 8.794A.74.74 0 002.25 9v1.5a3.75 3.75 0 001.5 3v6a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-6a3.75 3.75 0 001.5-3V9zM5.062 4.5h13.875l1.071 3.75H3.995L5.062 4.5zM9.75 9.75h4.5v.75a2.25 2.25 0 01-4.5 0v-.75zm-1.5 0v.75a2.25 2.25 0 01-4.5 0v-.75h4.5zm10.5 9.75H5.25v-5.325A3.75 3.75 0 009 12.75a3.75 3.75 0 006 0 3.75 3.75 0 003.75 1.425V19.5zM18 12.75a2.25 2.25 0 01-2.25-2.25v-.75h4.5v.75A2.25 2.25 0 0118 12.75z"
    ></path>
  </svg>
);

export default ShopDetails;
