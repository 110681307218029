// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sso-login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  height: 100vh;
}
.sso-login-wrapper h1 {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 42px;
}
@media screen and (max-width: 767px) {
  .sso-login-wrapper h1 {
    font-size: 24px;
    margin-bottom: 18px;
  }
}
.sso-login-wrapper h4 {
  font-size: 21px;
  font-weight: 500;
  color: #000;
  margin-bottom: 62px;
}
@media screen and (max-width: 767px) {
  .sso-login-wrapper h4 {
    font-size: 18px;
    margin-bottom: 52px;
  }
}
.sso-login-wrapper h4 a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.sso-login-text {
  max-width: 600px;
}
@media screen and (max-width: 767px) {
  .sso-login-text {
    max-width: 300px;
  }
}

.sso-container {
  position: relative;
}

.sso-kiko-logo {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .sso-kiko-logo {
    bottom: 120px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/SsoLoginError/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;AAEJ;AADI;EALF;IAMI,eAAA;IACA,mBAAA;EAIJ;AACF;AAFE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAIJ;AAHI;EALF;IAMI,eAAA;IACA,mBAAA;EAMJ;AACF;AALI;EACE,WAAA;EACA,qBAAA;EACA,gBAAA;AAON;;AAHA;EACE,gBAAA;AAMF;AALE;EAFF;IAGI,gBAAA;EAQF;AACF;;AANA;EACE,kBAAA;AASF;;AAPA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,2BAAA;AAUF;AATE;EALF;IAMI,aAAA;EAYF;AACF","sourcesContent":[".sso-login-wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  height: 100vh;\n  h1 {\n    font-size: 30px;\n    font-weight: bold;\n    color: #000;\n    margin-bottom: 42px;\n    @media screen and (max-width: 767px) {\n      font-size: 24px;\n      margin-bottom: 18px;\n    }\n  }\n  h4 {\n    font-size: 21px;\n    font-weight: 500;\n    color: #000;\n    margin-bottom: 62px;\n    @media screen and (max-width: 767px) {\n      font-size: 18px;\n      margin-bottom: 52px;\n    }\n    a {\n      color: #000;\n      text-decoration: none;\n      font-weight: 600;\n    }\n  }\n}\n.sso-login-text {\n  max-width: 600px;\n  @media screen and (max-width: 767px) {\n    max-width: 300px;\n  }\n}\n.sso-container {\n  position: relative;\n}\n.sso-kiko-logo {\n  position: absolute;\n  bottom: 70px;\n  left: 50%;\n  transform: translateX(-50%);\n  @media screen and (max-width: 767px) {\n    bottom: 120px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
