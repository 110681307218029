import React from "react";

const Scanner = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
       fill="currentColor"
      d="M6 15.75H3a.75.75 0 01-.75-.75v-3a.75.75 0 10-1.5 0v3A2.25 2.25 0 003 17.25h3a.75.75 0 100-1.5zm10.5-4.5a.75.75 0 00-.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 100 1.5h3A2.25 2.25 0 0017.25 15v-3a.75.75 0 00-.75-.75zM15 .75h-3a.75.75 0 100 1.5h3a.75.75 0 01.75.75v3a.75.75 0 101.5 0V3A2.25 2.25 0 0015 .75zm-13.5 6A.75.75 0 002.25 6V3A.75.75 0 013 2.25h3a.75.75 0 000-1.5H3A2.25 2.25 0 00.75 3v3a.75.75 0 00.75.75zm6-3h-3a.75.75 0 00-.75.75v3a.75.75 0 00.75.75h3a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75zm-.75 3h-1.5v-1.5h1.5v1.5zm3.75 1.5h3a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75v3a.75.75 0 00.75.75zm.75-3h1.5v1.5h-1.5v-1.5zM7.5 9.75h-3a.75.75 0 00-.75.75v3a.75.75 0 00.75.75h3a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75zm-.75 3h-1.5v-1.5h1.5v1.5zM10.5 12a.75.75 0 00.75-.75.75.75 0 100-1.5h-.75a.75.75 0 00-.75.75v.75a.75.75 0 00.75.75zm3-2.25a.75.75 0 00-.75.75v2.25a.75.75 0 100 1.5h.75a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75zm-3 3a.75.75 0 100 1.5.75.75 0 000-1.5z"
    ></path>
  </svg>
);

export default Scanner;
