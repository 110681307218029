import React from "react";

const Copy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.759 5.732L14.268 2.24a1.25 1.25 0 00-1.768 0l-9.634 9.635a1.24 1.24 0 00-.366.883v3.492a1.25 1.25 0 001.25 1.25h3.491a1.24 1.24 0 00.884-.367L17.759 7.5a1.25 1.25 0 000-1.768zM7.24 16.25H3.75v-3.492l6.875-6.875 3.491 3.492-6.875 6.875zM15 8.49L11.509 5l1.875-1.875 3.491 3.49L15 8.49z"
    ></path>
  </svg>
);

export default Copy;
