import React from "react";

const BankDetails = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.25 9.75H4.5v6H3a.75.75 0 000 1.5h18a.75.75 0 100-1.5h-1.5v-6h2.25a.75.75 0 00.393-1.389l-9.75-6a.75.75 0 00-.786 0l-9.75 6A.75.75 0 002.25 9.75zm3.75 0h3v6H6v-6zm7.5 0v6h-3v-6h3zm4.5 6h-3v-6h3v6zM12 3.88l7.1 4.37H4.9L12 3.88zM23.25 19.5a.75.75 0 01-.75.75h-21a.75.75 0 110-1.5h21a.75.75 0 01.75.75z"
    ></path>
  </svg>
);

export default BankDetails;
