import React from 'react';

const Loader = () => {
  return (
    <div className="loader">
      {/* Your loader animation goes here */}
      <h1>Loading...</h1>
    </div>
  );
};

export default Loader;