import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import Loader from "./components/Loader/Loader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <Suspense fallback={<Loader />}> {/* Use Suspense to render the loader */}
    <App />
    </Suspense>
  </React.StrictMode>
  
);

reportWebVitals();
