import React from "react";

const UploadFile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.5 14.25v5.25A1.5 1.5 0 0120 21H5a1.5 1.5 0 01-1.5-1.5v-5.25a.75.75 0 111.5 0v5.25h15v-5.25a.75.75 0 111.5 0zM9.28 8.03l2.47-2.47v8.69a.75.75 0 101.5 0V5.56l2.47 2.47a.75.75 0 101.06-1.061l-3.75-3.75a.749.749 0 00-1.06 0l-3.75 3.75A.75.75 0 109.28 8.03z"
    ></path>
  </svg>
);

export default UploadFile;
