import React, { useState } from "react";
import { get } from "lodash";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom";
// import Loader from "../../components/common/loader";
// import { sellerDetails } from '../../components/common/utils';
import APIKIKO from "../../api/api_kiko";
import { ADD_WALLET_MONEY } from "../../api/apiList";

const RazorpayModule = (props) => {
  const getSellerDetails = () => {
    try {
      return JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      return null;
    }
  };
  const Razorpay = useRazorpay();
  const [loadingSelectAddress, setLoadingSelectAddress] = useState(false);
  let orderDetails = {};
  const orderAmount = props.orderAmount;
  const transactionFee = props.transactionFee;
  const paymentMethod = props.paymentMethod;
  const navigate = useNavigate();

  const handlePayment = async (e) => {
    const userData = getSellerDetails();
    const body = {
      sellerId: userData && userData._id ? userData._id : "",
      amount: parseFloat(orderAmount),
      // bonusAmount: parseFloat(orderAmount),
      bonusAmount: 0,
      paymentType: "addwallet",
      transactionFee: transactionFee,
    };
    orderDetails = await addMoney(body);
    const razorpayId = get(orderDetails, "razorPayOrderId", "");
    const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY;

    const options = {
      key: razorpayKey,
      currency: "INR",
      name: "Kiko Live",
      description: "Kiko Seller Payment",
      image: "",
      order_id: razorpayId,
      handler: (response) => {
        if (
          response?.razorpay_signature &&
          response?.razorpay_signature != ""
        ) {
          navigate("/wallet", { state: { transection: "success" } });
        } else {
          navigate("/wallet", { state: { transection: "fail" } });
        }
      },
      prefill: {
        name: get(userData, "name", ""),
        email: "",
        contact: get(userData, "mobile", ""),
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
      config: {
        display: {
          hide:
            paymentMethod === "upi"
              ? [
                  { method: "card" },
                  { method: "wallet" },
                  { method: "netbanking" },
                  { method: "paylater" },
                ]
              : paymentMethod === "other"
              ? [{ method: "upi" }]
              : [],
        },
      },
    };
    const rzpay = new Razorpay(options);
    rzpay.open();
  };
  const addMoney = async (body) => {
    const addMoneyRes = await APIKIKO.post(ADD_WALLET_MONEY, body);
    return addMoneyRes?.data?.data;
  };
  return (
    <div className="button-loader  mb-4">
      <button className="Proceed-btn" onClick={handlePayment}>
        {loadingSelectAddress ? "" : "Proceed"}
      </button>
      <div className="dotLoader">
        {/* {loadingSelectAddress && <Loader />} */}
      </div>
    </div>
  );
};

export default RazorpayModule;
