export { default as Camera } from "./Camera";
export { default as UploadFile } from "./UploadFile";
export { default as RightArrow } from "./RIghtArrow";
export { default as Excel } from "./Excel";
export { default as Printer } from "./Printer";
export { default as ShopDetails } from "./ShopDetail";
export { default as SellerDetails } from "./SellerDetails";
export { default as StoreServiceable } from "./StoreServiceable";
export { default as BankDetails } from "./BankDetails";
export { default as Copy } from "./Copy";
export { default as Edit } from "./Edit";
export { default as InfoIcon } from "./Info-icon";
export { default as Download } from "./download";
export { default as Scanner } from "./ScannerIcon";
