import React from "react";

const Excel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    fill="none"
    viewBox="0 0 16 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.625.875h-10a1.25 1.25 0 00-1.25 1.25V4h-1.25a1.25 1.25 0 00-1.25 1.25v7.5A1.25 1.25 0 002.125 14h1.25v1.875a1.25 1.25 0 001.25 1.25h10a1.25 1.25 0 001.25-1.25V2.125a1.25 1.25 0 00-1.25-1.25zM11.5 7.125h3.125v3.75H11.5v-3.75zm3.125-1.25H11.5V5.25A1.25 1.25 0 0010.25 4V2.125h4.375v3.75zm-10-3.75H9V4H4.625V2.125zm-2.5 3.125h8.125v7.5H2.125v-7.5zm2.5 8.75H9v1.875H4.625V14zm5.625 1.875V14a1.25 1.25 0 001.25-1.25v-.625h3.125v3.75H10.25zm-6.105-5.4L5.375 9l-1.23-1.475a.625.625 0 11.96-.8l1.082 1.298L7.27 6.725a.625.625 0 11.96.8L7 9l1.23 1.475a.625.625 0 11-.96.8L6.188 9.977l-1.083 1.298a.625.625 0 01-.96-.8z"
    ></path>
  </svg>
);

export default Excel;
