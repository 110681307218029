import React, { useState, useEffect, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import ThreeDots from "../../images/threesot.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { read, utils } from "xlsx";
import BlueInfoIcon from "../../images/blue-info.svg";
import crossIcon from "../../images/cross-icon.svg";
import { Link } from "react-router-dom";
import {
  INVENTORY,
  DELETE_INVENTORY,
  UPDATE_CATELOGUE,
  CATELOGUE_BRAND,
  CATELOGUE_LIST,
  UPDATE_CATELOGUE_EXCEL,
  GET_CATELOGUE_VARIENT
} from "../../api/apiList";
import API from "../../api";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import DeleteModal from "../../components/DeleteModal/deletemodal";
import {
  notify,
  handleError,
  nomenclature,
  PaginationFilter,
} from "../../utils";
import DeleteIcon from "../../images/Inventry/delete-icon.svg";
import Select from 'react-select';
import EditIcon from "../../images/Inventry/edit-icon.svg";
//import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
//import { Pagination } from 'antd'
import { LoadingOutlined } from "@ant-design/icons";
//import crossIcon from "../../images/Categories/image-cross.svg";
import { Spin, Popover } from "antd";
import cartIcon from "../../images/Inventry/cart-icon.png";
import "./styles.scss";

function Inventory(props) {
  const getSellerDetails = () => {
    try {
      return JSON.parse(localStorage.getItem("user") || "");
    } catch (error) {
      return null;
    }
  };
  //const [editModal, setEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [editInventory, setEditInventory] = useState({});
  const [count, setcount] = useState({});
  const [search, setSearch] = useState("");
  const [clear, setclear] = useState(false);
  const [realCount, setrealCount] = useState(0);
  const [limit] = useState(20);
  //const [gst, setGst] = useState(0);
  const [page, setpage] = useState(1);
  const [stocks, setstocks] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [inventory, setinventory] = useState([]);
  const [brandName, setBrandName] = useState([]);
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [status, setstatus] = useState("");
  const [wrongRecord, setWrongRecord] = useState([])
  //const [availableQuantity, setavailableQuantity] = useState("");
  //const [price, setprice] = useState("");
  //const [discountedPrice, setdiscount] = useState("");
  const [componentMounted, setComponentMounted] = useState(false);
  const [user_data] = useState(getSellerDetails());
  const navigate = useNavigate();
  const isAppView = localStorage.getItem("isAppView") ?? null;
  const [isMobile, setIsMobile] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [variants, setVariants] = useState([]);
  const [openVarient, setOpenVarient] = useState(false);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedBrandName(selectedOption?.value)
  };

  const platformIcon = (
    <ul className="platformContent">
      <li className="mb-3">
        The <span>Buyer and Seller platform applies a 5% transaction fee</span>,
        which is deducted from the selling price of your item.{" "}
      </li>
      <li>
        Eg.{" "}
        <span>
          Selling Price - Platform Fees = Settlement amount. ₹500 - 5% = ₹475
        </span>
      </li>
    </ul>
  );

  const getInventory = async (loading) => {
    try {
      setLoading(loading);
      const obj = {
        userId: user_data && user_data._id ? user_data._id : "",
        status,
        stocks,
        limit,
        page,
        search,
        brand: selectedBrandName
      };
      const response = await API.post(INVENTORY, obj);
      setLoading(false);
      if (response?.data?.success) {
        setcount(response?.data?.data?.count);
        setrealCount(response?.data?.data?.catCount);
        setinventory(response?.data?.data?.results);
        setUpdateStatus("");
      } else {
        notify("error", "Please Login Again!");
        if (response?.data?.userUnauthorized) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie =
              name +
              "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=" +
              window.location.hostname;
          }
          navigate("/");
        }
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getCatelogueVarient = async (catelogData) => {
    try {
      const response = await API.post(GET_CATELOGUE_VARIENT, {
        _id: catelogData?._id,
      });
      if (response.data?.success) {
        console.log("response.data.result",response.data.result);
        
        setVariants(response.data.result);
        setOpenVarient(true)
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getInventoryList = async () => {
    try {
      setCsvLoading(true);
      const obj = {
        userId: user_data && user_data._id ? user_data._id : "",
        status: "active",
        stocks,
        limit,
        page,
        search,
        brand: selectedBrandName
      };
      const response = await API.post(CATELOGUE_LIST, obj);
      setCsvLoading(false);
      if (response?.data?.isSuccess) {
        window.open(
          response?.data?.data?.allExportData.file_url,
          `CatelogData.csv`
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (componentMounted) {
      getInventory(true);
      catelogBrand()
    } else {
      setComponentMounted(true);
    }
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [componentMounted, status, page]);

  const deleteInventor = async () => {
    const _id = editInventory._id;
    try {
      setShowModal(false);
      const response = await API.delete(DELETE_INVENTORY, { data: { _id } });
      if (response?.data?.success) {
        getInventory(true);
        setEditInventory({});
        notify("success", response?.data?.message);
      }
    } catch (error) {
      setEditInventory({});
      handleError(error);
    }
  };

  const catelogBrand = async () => {
    try {
      setShowModal(false);
      const response = await API.post(CATELOGUE_BRAND, { userId: user_data && user_data._id ? user_data._id : "", });
      if (response?.data?.success) {
        setBrandName(response?.data?.result)
      }
    } catch (error) {
      handleError(error);
    }
  };

  const onPageChanged = (page) => {
    setpage(page);
  };

  const updateStockStatus = async (Inv) => {
    let body = {
      availableQuantity: parseInt(Inv.availableQuantity) > 0 ? "0" : "250",
      _id: Inv && Inv._id ? Inv._id : "",
    };
    try {
      const response = await API.post(UPDATE_CATELOGUE, body);
      if (response?.data?.success) {
        getInventory(false);
        setOpenVarient(false);
        notify("success", response?.data?.message);
      } else {
        setUpdateStatus("");
        notify("error", response?.data?.message);
      }
    } catch (error) {
      setUpdateStatus("");
      handleError(error);
    }
  };

  const convertExcelToJson = (file) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      if (!file.name.endsWith(".xlsx")) {
        notify("error", "Invalid file format. Only XLSX files are allowed.");
        setLoading(false);
        return; // Return early and do not proceed further
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const filteredData = utils.sheet_to_json(worksheet, { header: 1 });

        const jsonData = filteredData.filter(
          (row) => Array.isArray(row) && row?.length > 0
        );
        const headers = jsonData[0];
        const headerTransformations = {
          'ID': '_id',
          "EAN": "productId",
          'Product Name': 'productName',
          'Available Quantity': 'availableQuantity',
          'Price': 'price',
          'Selling Price': 'discountedPrice',
        };
        const result = jsonData.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            const modifiedHeader = headerTransformations[header.trim()];
            if (modifiedHeader === '_id') {
              obj[modifiedHeader] = row[index].replace(/"/g, ''); // Remove double quotes
            } else {
              obj[modifiedHeader] = row[index];
            }
          });

          if (
            !obj.productId ||
            !obj._id ||
            !obj.price ||
            !obj.discountedPrice ||
            !(obj.availableQuantity >= 0) ||
            !obj.productName
          ) {
            wrongRecord.push(obj);
            return null;
          }

          return obj;
        });
        resolve(result);
      };

      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    try {
      const jsonData = await convertExcelToJson(file);
      const finalData = jsonData.filter((item) => { return item != null })
      if (wrongRecord.length > 0) { notify("error", `${wrongRecord.length} records are Wrong`); }
      const hasValidationError = finalData.some((item) => item === false);
      if (!hasValidationError) {
        const obj = {
          remittanceArray: finalData.flat(),
        }
        API.post(UPDATE_CATELOGUE_EXCEL, obj)
          .then(({ data }) => {
            if (data.success) {
              setWrongRecord([])
              toast(`Catelog updated successfully`)
              getInventory(true);
            } else {
              notify('error', 'Something went wrong')
            }
          })
          .catch(error => {
            handleError(error)
          })
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (clear) {
      getInventory(true);
      setclear(false)
    }
  }, [clear])

  const clearState = () => {
    setpage(1);
    setstocks("");
    setSearch("");
    setSelectedBrandName("")
    setSelectedOption(null)
    if (page === 1) { setclear(true); }
    else {
      setpage(1);
    }
  };

  const searchState = () => {
    if (page === 1) { getInventory(true) }
    else {
      setpage(1);
    }
  };

  return (
    <>
      <div
        className="RightBlock"
        style={
          isMobile
            ? {
              width: "100%",
              left: "0",
              top: isAppView === "true" ? "0px" : "68px",
            }
            : {}
        }
      >
        <div className="inventry-section">
          <div>
            <div className="section-title">
              <h1 className="m-0">Inventory</h1>
              <div className="d-flex gap-2">
                <button className="btn btn-sm btn-primary" onClick={() => getInventoryList()}>{csvLoading ? "Loading" : "Download Catalog"}</button>
                <label className="btn btn-sm btn-primary">Bulk Catalog Update
                  <input style={{ display: 'none' }} type="file" onChange={(e) => handleFileChange(e)} />
                </label>
              </div>
            </div>
            {/* <label className='pinkbtn m-0'>
              <span>Upload file</span>
              <input style={{ display: 'none' }} type="file" onChange={(e)=>handleFileChange(e)} />
            </label> */}
            <ul className="nav nav-pills" role="tablist">
              <li className={status === "" ? "nav-item active" : "nav-item"}>
                <a
                  data-toggle="tab"
                  onClick={() => {
                    setpage(1);
                    setstatus("");
                  }}
                >
                  All<span>({count.totalCount})</span>
                </a>
              </li>
              <li
                className={status === "active" ? "nav-item active" : "nav-item"}
              >
                <a
                  data-toggle="tab"
                  onClick={() => {
                    setpage(1);
                    setstatus("active");
                  }}
                >
                  Active<span>({count.activeCount})</span>
                </a>
              </li>
              <li
                className={
                  status === "pending" ? "nav-item active" : "nav-item"
                }
              >
                <a
                  data-toggle="tab"
                  onClick={() => {
                    setpage(1);
                    setstatus("pending");
                  }}
                >
                  Pending<span>({count.pendingCount})</span>
                </a>
              </li>
              <li
                className={
                  status === "rejected" ? "nav-item active" : "nav-item"
                }
              >
                <a
                  data-toggle="tab"
                  onClick={() => {
                    setpage(1);
                    setstatus("rejected");
                  }}
                >
                  Rejected<span>({count.rejectedCount})</span>
                </a>
              </li>
              <li
                className={
                  status === "drafted" ? "nav-item active" : "nav-item"
                }
              >
                <a
                  data-toggle="tab"
                  onClick={() => {
                    setpage(1);
                    setstatus("drafted");
                  }}
                >
                  Drafted<span>({count.draftedCount})</span>
                </a>
              </li>
            </ul>
            <div className="col-lg-12">
              <div className="tab-content">
                <div id="home" className="tab-pane active">
                  <div className="filter">
                    <span className="inventory-select-span p-0">
                      <label>Search By: </label>
                      <span className="inventory-select-span">
                        <input
                          type="text"
                          value={search}
                          placeholder="Product Name/EAN Number"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </span>
                    </span>
                    <span className="inventory-select-span p-0">
                      <label>Brand Filter:</label>
                      <span className="inventory-select-span" style={{ width: "250px", height: "39px" }}>
                        <Select
                          value={selectedOption}
                          onChange={handleChange}
                          placeholder={"Select Brand..."}
                          options={brandName.map(option => ({ value: option, label: option }))}
                        />
                      </span>
                    </span>
                    <span className="inventory-select-span p-0">
                      <label>Filter By:</label>
                      <span className="inventory-select-span">
                        <select
                          className="form-select inventory-select"
                          style={{ padding: "9px 10px" }}
                          onChange={(e) => {
                            setstocks(e.target.value);
                          }}
                          value={stocks}
                        >
                          <option value={""}>Select</option>
                          <option value={"0"}>In Stocks</option>
                          <option value={"1"}>Out Of Stocks</option>
                          <option value={"50"}>Low Stocks</option>
                        </select>
                      </span>
                    </span>

                    <button
                      onClick={() => {
                        searchState()
                      }}
                      className="btn btn-primary btn-sm me-2"
                    >
                      Search
                    </button>
                    <button
                      onClick={() => {
                        clearState()
                      }}
                      className="btn btn-sm btn-outline"
                    >
                      Clear
                    </button>
                  </div>

                  {inventory.length > 0 ? (
                    <>
                      <div className="inventory-container">
                        <div className="table-responsive">
                          {loading ? (
                            <Spin indicator={antIcon} className="loader" />
                          ) : (
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Sr No.</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Product Name</th>
                                  <th scope="col">Action</th>
                                  <th scope="col">Stock Status</th>
                                  <th scope="col">Available Quantity</th>
                                  <th scope="col">Net Weight</th>
                                  <th scope="col">Unit</th>
                                  <th scope="col">Price</th>
                                  <th scope="col">Discount Price</th>
                                  <th scope="col">EAN/Barcode Number</th>
                                  <th scope="col">Country of origin</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {inventory.map((Inv, index) => {
                                  return (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>
                                        {" "}
                                        {Inv.status === "rejected" ? (
                                          <p className="red status-border">
                                            {" "}
                                            {nomenclature(Inv.status)}{" "}
                                          </p>
                                        ) : Inv.status === "pending" ? (
                                          <p className="yellow status-border">
                                            {" "}
                                            {nomenclature(Inv.status)}{" "}
                                          </p>
                                        ) : Inv.status === "active" ? (
                                          <p className="Darkgreen status-border">
                                            {" "}
                                            {nomenclature(Inv.status)}{" "}
                                          </p>
                                        ) : Inv.status === "drafted" ? (
                                          <p className="blue status-border">
                                            {" "}
                                            {nomenclature(Inv.status)}{" "}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                      <td>{Inv.productName}</td>
                                      <td>
                                        {Inv.isVariant && (
                                          <button
                                            onClick={() => {
                                              getCatelogueVarient(Inv)
                                              // navigate("/categories", {
                                              //   state: { catelogData: Inv },
                                              // });
                                            }}
                                            className="btn btn-primary btn-sm me-2"
                                          >
                                            View variant
                                          </button>
                                        )}
                                      </td>
                                      <td>
                                        {updateStatus == Inv._id ? (
                                          <Spin
                                            indicator={antIcon}
                                            size="small"
                                          />
                                        ) : (
                                          <div className="invSwitchBtn">
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  parseInt(
                                                    Inv.availableQuantity
                                                  ) > 0
                                                    ? true
                                                    : false
                                                }
                                                disabled={updateStatus != ""}
                                                onChange={() => {
                                                  setUpdateStatus(Inv._id);
                                                  updateStockStatus(Inv);
                                                }}
                                              />
                                              <span className="slider round"></span>
                                            </label>
                                          </div>
                                        )}
                                      </td>
                                      <td>{Inv.availableQuantity}</td>
                                      <td>{Inv.weight}</td>
                                      <td>{Inv.weightUnit}</td>
                                      <td>{Inv.price}</td>
                                      <td>{Inv.discountedPrice}</td>
                                      <td>{Inv.productId}</td>
                                      <td>{Inv.countryOfOrigin}</td>
                                      <td>
                                        <button
                                          onClick={() => {
                                            navigate("/fb-categories", {
                                              state: { catelogData: Inv },
                                            });
                                          }}
                                        >
                                          <img
                                            src={EditIcon}
                                            alt=""
                                            variant="outlined"
                                            color="neutral"
                                          />
                                        </button>
                                        <button
                                          onClick={() => {
                                            setShowModal(true);
                                            setEditInventory(Inv);
                                          }}
                                        >
                                          <img src={DeleteIcon} alt="" />
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                      <div className="mobile-inventory">
                        <div className="inventory-flex">
                          {loading ? (
                            <Spin indicator={antIcon} className="loader" />
                          ) : inventory.map((Inv, index) => {
                            return (
                              <div className="inventory-list" key={index}>
                                <div className="d-flex align-items-center justify-content-between mb-2" style={{ position: "relative" }}>
                                  <h6 className="m-0"> {Inv.productName.length > 35 ? Inv.productName.slice(0, 35) + "..." : Inv.productName}</h6>
                                  <div className="threedotsDropdown">
                                    <div className="dropdown">
                                      <button
                                        className=" threedots"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        aria-haspopup="true"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img
                                          src={ThreeDots}
                                          className="three-dots"
                                          alt="Three Dots"
                                        />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <p className="dropdown-item m-0"
                                          onClick={() => {
                                            navigate("/fb-categories", {
                                              state: { catelogData: Inv },
                                            });
                                          }}
                                        >Edit</p>
                                        <p className="dropdown-item m-0"
                                          onClick={() => {
                                            setShowModal(true);
                                            setEditInventory(Inv);
                                          }} >
                                          Delete
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <p>EAN / Barcode No: {Inv.productId}</p>
                                  {Inv.status === "rejected" ? (
                                    <div className="yellow-border">Inacitve</div>
                                  ) : Inv.status === "pending" ? (
                                    <div className="red-border">Pending</div>
                                  ) : Inv.status === "active" ? (
                                    <div className="green-border">Active</div>
                                  ) : Inv.status === "drafted" ? (
                                    <p className="blue status-border">
                                      {" "}
                                      {nomenclature(Inv.status)}{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}

                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                  <div className="d-flex align-items-center gap-2">
                                    <p>
                                      Price: <span>₹{Inv.price}</span>
                                    </p>
                                    <span className="divder"></span>
                                    <p>
                                      Discount Price: <span>₹{Inv.discountedPrice}</span>
                                    </p>
                                  </div>
                                  <p>Stock Status:</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="d-flex align-items-center gap-2">
                                    <p>Net weight: {Inv.weight}{" "}{Inv.weightUnit}</p>
                                    <span className="divder"></span>
                                    <p>Available Qty:{Inv.availableQuantity}</p>
                                  </div>
                                  <div className="inventory-switch">
                                    <div className="invSwitchBtn p-0">
                                      <label className="switch">
                                        {updateStatus == Inv._id ? (
                                          <Spin
                                            indicator={antIcon}
                                            size="small"
                                          />
                                        ) : (
                                          <>
                                            <input
                                              type="checkbox"
                                              checked={
                                                parseInt(
                                                  Inv.availableQuantity
                                                ) > 0
                                                  ? true
                                                  : false
                                              }
                                              disabled={updateStatus != ""}
                                              onChange={() => {
                                                setUpdateStatus(Inv._id);
                                                updateStockStatus(Inv);
                                              }}
                                            />
                                            <span className="slider round"></span>
                                          </>
                                        )}
                                        {/* <span className="slider round"></span> */}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </>
                  ) : loading ? (
                    <Spin indicator={antIcon} className="loader" size="large" />
                  ) : (
                    <div className="no-data-status">
                      <div className="upload-catalog-modal">
                        <div className="cart-icon">
                          <img src={cartIcon} alt="" />
                        </div>
                        <p>No Catalogue has been Uploaded </p>
                        <p>Kindly Upload Catalogue </p>
                        <div className="d-flex gap-2 mt-3 justify-content-center">
                          <Link
                            to="/addsinglecatalog"
                            className="btn btn-primary"
                          >
                            {" "}
                            Upload Catalogue
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* <div id="menu1" className=" tab-pane"></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <PaginationFilter
            onPageChanged={onPageChanged}
            limit={limit}
            count={realCount}
            page={page}
          />
        </div>
      </div>
      <Modal
        open={openVarient}
        onClose={() => {
          setOpenVarient(false);
        }}
        classNames={{
          modal: 'custom-modal- view-variants-modal' }}
        center
      >
        <div className="add-variants-table">
          <div className="d-flex align-items-center  mb-3" style={{ paddingLeft: '30px' }}>
            <h3>View Variants</h3>
            <div className="PlatPopover m-0">
              <Popover content={platformIcon} trigger="hover">
                <img src={BlueInfoIcon} className="me-1 " alt="" />
              </Popover>
              Platform Fees (5%)
            </div>
            <img
            src={crossIcon}
            className="varientCross"
            alt=""
            onClick={() => {
              setOpenVarient(false);
            }}
          />
          </div>
          <div className="table-responsive">
            <table className="table m-0">
              <thead>
                <tr>
                <td>
                EAN Number 
                  </td>
                  <td>
                    Net Weight(gms/kg)
                  </td>
                  <td>
                    Unit
                  </td>
                  <td>
                    Available Quantity
                  </td>
                  <td>
                    Price
                  </td>
                  <td>
                    Selling Price
                  </td>
                  <td>Settlement Price</td>
                  <td>Stock Status</td>
                </tr>
              </thead>
              <tbody>
                {variants.map((variant, index) => (
                  <tr
                    style={{ verticalAlign: "middle" }}
                    key={index}
                  >
                     <td>
                      <input
                        type="text"
                         placeholder="Enter EAN Number*"
                        className="form-control"
                        disabled
                        value={variant.productId}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter Net Weight*"
                        className="form-control"
                        disabled
                        value={variant.weight}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter Net Weight*"
                        className="form-control"
                        disabled
                        value={variant.weightUnit}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter Quantity*"
                        className="form-control"
                        disabled
                        value={variant.availableQuantity}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter Price*"
                        className="form-control"
                        disabled
                        value={variant.price}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder="Selling Price*"
                        className="form-control"
                        disabled
                        value={variant.discountedPrice}
                      />
                    </td>
                    <td>
                      {variant.discountedPrice !== 0 &&
                        variant.discountedPrice !== "" && (
                          <label className="form-control">
                            {variant.discountedPrice -
                              variant.discountedPrice * 0.05}
                          </label>
                        )}
                    </td>
                    <td>
                      <td>
                        {updateStatus == variant._id ? (
                          <Spin
                            indicator={antIcon}
                            size="small"
                          />
                        ) : (
                          <div className="invSwitchBtn">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  parseInt(
                                    variant.availableQuantity
                                  ) > 0
                                    ? true
                                    : false
                                }
                                disabled={updateStatus != ""}
                                onChange={() => {
                                  setUpdateStatus(variant._id);
                                  updateStockStatus(variant);
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        )}
                      </td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      <DeleteModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setEditInventory({});
        }}
        onSubmit={() => {
          deleteInventor();
        }}
        title={"Your Lot"}
      />
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={3000}
        toastStyle={{ backgroundColor: "crimson" }}
      />
    </>
  );
}
export default Inventory;
