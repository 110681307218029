import React from "react";

const Printer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    fill="none"
    viewBox="0 0 23 23"
    {...props}
  >
    <path
      fill="#59399F"
      d="M18.587 6.686h-1.264V3.929a.69.69 0 00-.69-.69H5.605a.69.69 0 00-.69.69v2.757H3.651c-1.203 0-2.182.928-2.182 2.068v6.893a.69.69 0 00.689.689h2.757v2.757a.69.69 0 00.69.69h11.028a.69.69 0 00.69-.69v-2.757h2.757a.69.69 0 00.69-.69V8.755c0-1.14-.98-2.068-2.183-2.068zM6.294 4.618h9.65v2.068h-9.65V4.618zm9.65 13.786h-9.65v-4.136h9.65v4.136zm3.447-3.447h-2.068V13.58a.69.69 0 00-.69-.69H5.605a.69.69 0 00-.69.69v1.378H2.847V8.754c0-.38.361-.69.804-.69h14.936c.443 0 .804.31.804.69v6.203zm-2.068-4.48a1.034 1.034 0 11-2.069 0 1.034 1.034 0 012.069 0z"
    ></path>
  </svg>
);

export default Printer;
