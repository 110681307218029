import React from "react";

const InfoIcon = (props) => (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
   fill="currentColor"
        d="M9 .875A8.125 8.125 0 1017.125 9 8.133 8.133 0 009 .875zm0 15A6.875 6.875 0 1115.875 9 6.883 6.883 0 019 15.875zm1.25-3.125a.624.624 0 01-.625.625 1.25 1.25 0 01-1.25-1.25V9a.625.625 0 010-1.25A1.25 1.25 0 019.625 9v3.125a.624.624 0 01.625.625zm-2.5-7.188a.937.937 0 111.875 0 .937.937 0 01-1.875 0z"
      ></path>
    </svg>
);

export default InfoIcon;
