import React from "react";

const SellerDetails = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.649 19.875c-1.428-2.469-3.629-4.239-6.196-5.078a6.75 6.75 0 10-6.906 0c-2.568.838-4.768 2.608-6.196 5.078a.75.75 0 101.299.75C5.416 17.572 8.538 15.75 12 15.75c3.462 0 6.584 1.822 8.35 4.875a.75.75 0 101.299-.75zM6.749 9a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0z"
    ></path>
  </svg>
);

export default SellerDetails;
