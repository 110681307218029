import React from "react";

const Edit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.375 2.5h-10a.625.625 0 00-.625.625V6.25H3.625A.625.625 0 003 6.875v10a.625.625 0 00.625.625h10a.624.624 0 00.625-.625V13.75h3.125a.624.624 0 00.625-.625v-10a.625.625 0 00-.625-.625zM13 16.25H4.25V7.5H13v8.75zm3.75-3.75h-2.5V6.875a.625.625 0 00-.625-.625H8v-2.5h8.75v8.75z"
    ></path>
  </svg>
);

export default Edit;
